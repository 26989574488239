@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Favorit';
  src: url('/fonts/favorit-bold.woff2') format('woff2'),
    url('/fonts/favorit-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Favorit';
  src: url('/fonts/favorit-regular.woff2') format('woff2'),
    url('/fonts/favorit-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Tobias';
  src: url('/fonts/tobias-light.woff2') format('woff2'),
    url('/fonts/tobias-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* Remove arrows from number inputs */
@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
